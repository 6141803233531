<template>
  <div>
    <div v-if="dataLoading" class="custom-loader-wrapper">
      <div class="custom-loader"></div>
    </div>
    <template v-else-if="pageData">
      <div
        v-if="pageData.Banner"
        class="full-width style-105126"
        :style="{
          'background-image': `linear-gradient(to top, transparent, rgba(0, 0, 0, 0.5)), url(${smallMedia(
            pageData.Banner.Image.data.attributes
          )})`,
        }"
      ></div>

      <div
        v-if="pageData.AboutInfo"
        class="style-105032 region layout-region indi-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img
                                src="@/assets/images/aboutHeading.png"
                                alt=""
                              /> -->
                              <span class="heading-black">
                                {{ pageData.AboutInfo.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.AboutInfo.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div class="first-col page-col col">
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content">
                          <div class="content-wrap">
                            <div
                              class="my-content"
                              v-html="
                                convertSpecialChars(pageData.AboutInfo.Details)
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.Vision.length > 0"
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  v-for="item in pageData.Vision"
                  :key="item.id"
                  class="layout_block_177570 region page-region set-bottom-margin"
                  id="page_region_174764"
                >
                  <div class="page-row row" style="margin: 0">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="@/assets/images/vision.png" alt="" /> -->
                              <span class="heading-black">
                                {{ item.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ item.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col page-col span12 col single-image-wrapper"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      :class="{ 'image-right': item.Side == 'Right' }"
                    >
                      <div
                        class="page-block style-103675 clearfix"
                        data-cid="1"
                        id="content_3120486"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <img
                                class="center-content-image"
                                :src="smallMedia(item.Image.data.attributes)"
                                alt=""
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="first-col page-col span12 col center-content-wrapper"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content">
                          <div class="center-content">
                            <div
                              class="my-content"
                              v-html="convertSpecialChars(item.Details)"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.ElixirStaff"
        class="style-103644 region layout-region"
        id="layout_region_3"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="style-103676 page-row row">
                    <div
                      class="first-col page-col span16 col other-video"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <div
                        class="page-block style-103675 clearfix"
                        data-cid="1"
                        id="content_3120486"
                      >
                        <div class="content text">
                          <div class="content-wrap bgvid-dimensions">
                            <video
                              playsinline=""
                              controls
                              controlslist="nodownload"
                              id="bgvid"
                              style="width: 100%"
                            >
                              <source
                                type="video/mp4"
                                :src="
                                  smallMedia(
                                    pageData.ElixirStaff.Video.data.attributes
                                  )
                                "
                              />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="last-col page-col span8 col center-content-wrapper"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div
                        class="page-block style-103675 clearfix"
                        data-cid="1"
                        id="content_3121855"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img
                                src="@/assets/images/staffHeading.png"
                                alt=""
                              /> -->
                              <span class="heading-black side-heading">
                                {{ pageData.ElixirStaff.HeadingBlack }}
                              </span>
                              <span class="heading-red side-heading">
                                {{ pageData.ElixirStaff.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <div
                          class="my-content"
                          v-html="
                            convertSpecialChars(pageData.ElixirStaff.Details)
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.ElixirValue"
        class="style-105032 region layout-region indi-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="@/assets/images/value.png" alt="" /> -->
                              <span class="heading-black">
                                {{ pageData.ElixirValue.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.ElixirValue.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="page-row row"
                    data-aos="fade-up"
                    data-aos-duration="900"
                  >
                    <div
                      class="my-content"
                      v-html="convertSpecialChars(pageData.ElixirValue.Details)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.QEC"
        class="style-105032 region layout-region indi-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="@/assets/images/qec.png" alt="" /> -->
                              <span class="heading-black">
                                {{ pageData.QEC.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.QEC.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="page-row row"
                    data-aos="fade-up"
                    data-aos-duration="900"
                  >
                    <div
                      class="my-content"
                      v-html="convertSpecialChars(pageData.QEC.Details)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="pageData.Form" class="style-104895">
        <div class="container">
          <div class="form-container">
            <div class="form-wrapper">
              <form @submit.prevent="formSubmit">
                <h2 class="form-heading">{{ pageData.Form.Heading }}</h2>

                <div class="form-group-wrapper">
                  <div class="form-group">
                    <label class="form-label" for="name">Name</label>
                    <input
                      class="form-control"
                      type="text"
                      name="name"
                      id="name"
                      v-model.trim="mailObj.name"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="email">Email</label>
                    <input
                      class="form-control"
                      type="email"
                      name="email"
                      id="email"
                      v-model="mailObj.email"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label" for="phone">Phone</label>
                  <input
                    class="form-control"
                    type="number"
                    name="phone"
                    id="phone"
                    v-model="mailObj.phone"
                    required
                  />
                </div>
                <div class="form-group">
                  <label class="form-label" for="msg">Message</label>
                  <textarea
                    class="form-control"
                    name="msg"
                    id="msg"
                    rows="4"
                    v-model.trim="mailObj.message"
                    required
                  />
                </div>
                <div class="form-button-wrapper">
                  <button
                    type="submit"
                    class="button-primary variant-secondary"
                    :disabled="request"
                  >
                    <div
                      v-if="request"
                      class="custom-mini-loader"
                      style="margin: auto"
                    ></div>
                    <span v-else>{{ pageData.Form.ButtonText }}</span>
                  </button>
                </div>
              </form>
            </div>
            <div class="form-info">
              <h2 class="form-info-heading">
                {{ pageData.Form.CardHeading }}
              </h2>
              <hr />
              <div
                v-for="item in pageData.Form.Points"
                :key="item.id"
                class="form-info-item"
              >
                <!-- arrow-white -->
                <img src="@/assets/images/arrow-red.png" alt="" />
                {{ item.Text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="custom-loader-wrapper">
      <h1>Page Not Found 🕵🏻‍♀️</h1>
      <p>
        Oops! 😖 The page you're looking for does not exist or have been
        temporarily unavailable.
      </p>
    </div>
  </div>
</template>
<script>
import { convertSpecialChars, smallMedia } from "@/utils/utils";
import axios from "axios";

export default {
  name: "AboutNew",
  data() {
    return {
      mailObj: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      request: false,
      // receiver: "aseefjawaid123@gmail.com",
      receiver: "officesupport@theelixirschools.com",
      pageData: null,
      dataLoading: false,
    };
  },
  created() {
    this.LoadData();
  },
  methods: {
    convertSpecialChars,
    smallMedia,
    formSubmit() {
      // console.log(this.mailObj);
      this.request = true;

      const config = {
        method: "post",
        url: "https://mail.appicksolutions.com/cloudious",
        data: {
          emailTo: this.receiver,
          subject: "The Elixir School | Contact",
          text: `Name: ${this.mailObj.name}\nEmail: ${this.mailObj.email}\nPhone: ${this.mailObj.phone}\nMessage: ${this.mailObj.message}`,
          html: "",
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res);
          if (res.data.status == "success") {
            this.$toast.success("Message sent successfully!");
            this.mailObj = {
              name: "",
              email: "",
              phone: "",
              message: "",
            };
          } else {
            this.$toast.error("Something went wrong!");
          }
        })
        .catch((err) => {
          // console.log(err);
          this.$toast.error("Something went wrong!");
        })
        .finally(() => (this.request = false));
    },
    LoadData() {
      this.dataLoading = true;
      let config = {
        method: "get",
        url: `${this.$store.state.domain}/about-uses?populate=deep`,
        headers: {
          Authorization: this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            this.pageData = res.data.data[0].attributes;
          } else this.pageData = null;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.dataLoading = false));
    },
  },
};
</script>
<style></style>
